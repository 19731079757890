import AddIcon, {
  default as AddCircleOutlineIcon,
} from "@mui/icons-material/AddCircleOutline";
import AddAPhotoIcon from "@mui/icons-material/AddPhotoAlternate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateProductById } from "../../../api/firestoreApi";
import { useGetProductById } from "../../../hooks/useGetProductById";
import "./EditProduct.css";

const categoriesList = [
  "New-Arrivals",
  "NR-WR-Series",
  "Airless",
  "Bottles",
  "Makeup",
  "Jars",
  "Droppers",
  "Bamboo",
];

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  backgroundColor: "#f4f4f4",
  border: "1px solid #c1c1c1",
  marginTop: "10px",
});

export default function EditProduct() {
  const navigate = useNavigate();
  const location = useLocation();
  const productId = location.state.rowId;
  const { error, data } = useGetProductById(productId);

  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [MOQ, setMOQ] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [imagesToShow, setImagesToShow] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [additionalData, setAdditionalData] = useState({});
  const [newDataKey, setNewDataKey] = useState("");
  const [newDataValue, setNewDataValue] = useState("");
  const [editKey, setEditKey] = useState("");
  const [editedData, setEditedData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAddFieldOpen, setIsAddFieldOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (data) {
      setId(data.id);
      setTitle(data.title);
      setCategory(data.category);
      setMOQ(parseInt(data.MOQ));
      setMainImage(data.images[0]);
      setImagesToShow(data.images);
      setImagesData(data.images);
      setAdditionalData(data.additionalData);
      setIsLoading(false);
    } else {
      setErrorMessage(error);
    }
  }, [data, error]);

  const handleImageEditProduct = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const imageDataURL = event.target.result;

      if (!imagesToShow.includes(imageDataURL)) {
        const updatedImages = [...imagesToShow, imageDataURL];
        setImagesToShow(updatedImages);

        const updateList = [...imagesData, file];
        setImagesData(updateList);

        if (imagesToShow.length === 0) {
          setMainImage(imageDataURL);
        }
      } else {
        setErrorMessage("Image already exists in the list");
      }
    };

    reader.readAsDataURL(file);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...imagesToShow];
    updatedImages.splice(index, 1);
    setImagesToShow(updatedImages);

    const updatedImagesData = [...imagesData];
    updatedImagesData.splice(index, 1);
    setImagesData(updatedImagesData);

    if (mainImage === imagesToShow[index]) {
      const nextMainImage = updatedImages.length > 0 ? updatedImages[0] : "";
      setMainImage(nextMainImage);
    }
  };

  const handleAddNewData = () => {
    let dataKey = newDataKey.replace(/:/g, "").trim();
    let dataValue = newDataValue.trim();

    if (dataKey && dataValue) {
      const updatedAdditionalData = {
        ...additionalData,
        [dataKey]: dataValue,
      };
      setAdditionalData(updatedAdditionalData);
      setNewDataKey("");
      setNewDataValue("");
    }
  };

  const handleRemoveNewData = (key) => {
    const updatedAdditionalData = { ...additionalData };
    delete updatedAdditionalData[key];
    setAdditionalData(updatedAdditionalData);
  };

  const handleEdit = (key, value) => {
    setEditKey(key);
    setEditedData(`${key}: ${value}`);
  };

  const handleSaveChanges = (oldKey) => {
    const [newKey, newValue] = editedData.split(":").map((part) => part.trim());

    if (oldKey !== newKey) {
      const { [oldKey]: removedKey, ...rest } = additionalData;
      setAdditionalData(rest);
    }

    setAdditionalData((prevData) => ({
      ...prevData,
      [newKey]: newValue,
    }));

    setEditKey(null);
    setEditedData("");
  };

  const handleUploadData = async () => {
    setErrorMessage(null);
    setIsLoading(true);

    let dataTitle = title.trim();

    if (newDataKey || newDataValue) {
      setIsLoading(false);
      setErrorMessage("Add Product fields are not empty!");
      return;
    } else if (!dataTitle || MOQ === 0) {
      setIsLoading(false);
      setErrorMessage("One or more of the required fildes is empty!");
      return;
    } else if (imagesData.length === 0) {
      setIsLoading(false);
      setErrorMessage("Please add at least one image.");
      return;
    }

    try {
      const data = {
        id: id,
        title: dataTitle,
        category: category,
        MOQ: `${MOQ} pcs`,
        images: imagesData,
        additionalData: additionalData,
      };

      await updateProductById(id, data)
        .then((product) => {
          if (product) {
            let rowId = id;
            navigate(`/products/${id}`, { replace: false, state: { rowId } });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error uploading product:", error);
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="EditProduct">
      {errorMessage && (
        <p
          style={{
            color: "red",
            margin: 0,
            padding: 10,
            fontSize: "1.2rem",
            fontWeight: 600,
          }}
        >
          {errorMessage}
        </p>
      )}

      <div className="EditProduct_top-container">
        <IconButton aria-label="back" size="small" onClick={() => navigate(-1)}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>

        <Button
          variant="contained"
          startIcon={isLoading ? <CircularProgress /> : <CloudUploadIcon />}
          disabled={isLoading ? true : false}
          color="info"
          onClick={handleUploadData}
          sx={{
            maxWidth: "200px",
            width: "20vw",
          }}
        >
          Update
        </Button>
      </div>

      <div className="EditProduct_top">
        <div className="EditProduct_data">
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            size="small"
            onChange={(e) => setTitle(e.target.value)}
            value={title || ""}
            sx={{ maxWidth: "500px", width: "40vw" }}
            disabled={isLoading ? true : false}
          />
          {mainImage ? (
            <img className="EditProduct_main-image" src={mainImage} alt={""} />
          ) : (
            <div className="EditProduct_top_main-image_empty" />
          )}
          <div className="EditProduct_top_images_container">
            {imagesToShow.length > 0 &&
              imagesToShow.map((image, index) => {
                return (
                  <div key={index} className="EditProduct_top_image-container">
                    <img
                      className="EditProduct_top_image"
                      src={image}
                      alt=""
                      onClick={() => setMainImage(image)}
                    />
                    <IconButton
                      aria-label="delete"
                      fontSize="small"
                      color="error"
                      sx={{ position: "absolute", top: 10, right: 10 }}
                      onClick={() => handleDeleteImage(index)}
                      disabled={isLoading ? true : false}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                );
              })}
            <Button
              aria-label="add an image"
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              sx={{
                width: 85,
                height: 85,
                backgroundColor: "#cecece",
                border: "1px solid #c1c1c1",
                margin: "5px 10px 0 0",
              }}
              onChange={(e) => handleImageEditProduct(e)}
              disabled={isLoading ? true : false}
            >
              <AddAPhotoIcon />
              <VisuallyHiddenInput type="file" />
            </Button>
          </div>
        </div>
        <div className="EditProduct_data">
          <TextField
            margin="normal"
            required
            fullWidth
            id="sku"
            label="SKU"
            name="sku"
            size="small"
            value={id || ""}
            onChange={(e) => setId(e.target.value)}
            sx={{ maxWidth: "500px", width: "30vw" }}
            disabled
          />

          <FormControl
            size="small"
            sx={{
              maxWidth: "500px",
              width: "30vw",
              textAlign: "left",
              marginTop: 1,
            }}
          >
            <InputLabel id="category">Category</InputLabel>
            <Select
              labelId="search-by-category"
              id="search-by-category"
              label="Search by Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              disabled={isLoading ? true : false}
            >
              {categoriesList.map((category, index) => (
                <MenuItem key={index} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {Object.entries(additionalData).map(([key, value], index) => (
            <TextField
              key={index}
              margin="normal"
              required
              fullWidth
              id={key}
              name={key}
              size="small"
              value={editKey === key ? editedData : `${key}: ${value}`}
              onChange={(e) => setEditedData(e.target.value)}
              sx={{ maxWidth: "500px", width: "30vw" }}
              disabled={editKey !== key}
              InputProps={{
                endAdornment: (
                  <>
                    {editKey === key ? (
                      <IconButton
                        aria-label="save changes"
                        edge="end"
                        onClick={() => handleSaveChanges(key)}
                        disabled={isLoading}
                      >
                        <SaveIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="edit entry"
                        edge="end"
                        onClick={() => handleEdit(key, value)}
                        disabled={isLoading}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton
                      aria-label="remove entry"
                      edge="end"
                      onClick={() => handleRemoveNewData(key)}
                      disabled={isLoading}
                    >
                      <RemoveIcon sx={{ color: "red" }} />
                    </IconButton>
                  </>
                ),
              }}
            />
          ))}

          <TextField
            margin="normal"
            type="number"
            required
            fullWidth
            id="moq"
            label="MOQ"
            name="moq"
            size="small"
            value={MOQ || ""}
            onChange={(e) => setMOQ(e.target.value)}
            sx={{ maxWidth: "500px", width: "30vw" }}
            disabled={isLoading ? true : false}
          />

          {isAddFieldOpen ? (
            <div className="Create_text-container">
              <TextField
                margin="normal"
                required
                fullWidth
                id="key"
                label="Key"
                name="key"
                size="small"
                value={newDataKey || ""}
                sx={{ maxWidth: "160px", width: "30vw" }}
                onChange={(e) => setNewDataKey(e.target.value)}
                disabled={isLoading ? true : false}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="value"
                label="Value"
                name="value"
                size="small"
                value={newDataValue || ""}
                sx={{ maxWidth: "390px", width: "30vw" }}
                onChange={(e) => setNewDataValue(e.target.value)}
                disabled={isLoading ? true : false}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleAddNewData}
                      edge="end"
                    >
                      <AddIcon sx={{ color: "green" }} />
                    </IconButton>
                  ),
                }}
              />
              <IconButton
                aria-label="remove entry"
                onClick={() => setIsAddFieldOpen(false)}
                sx={{ margin: "7px 0 0" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <IconButton
              aria-label="Add fields"
              size="large"
              onClick={() => setIsAddFieldOpen(true)}
              sx={{ alignSelf: "center" }}
            >
              <AddCircleOutlineIcon fontSize="large" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
}
